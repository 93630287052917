import React from "react";

const IllustrationsIcon = (props) => (
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_374_19)">
      <path
        d="M110.84 202.13C161.098 202.13 201.84 161.388 201.84 111.13C201.84 60.8721 161.098 20.13 110.84 20.13C60.5821 20.13 19.84 60.8721 19.84 111.13C19.84 161.388 60.5821 202.13 110.84 202.13Z"
        fill="#F5B4AA"
      />
      <path
        d="M147.481 39.6469L52.7675 57.2182C51.41 57.47 50.5136 58.7747 50.7655 60.1323L73.3055 181.629C73.5574 182.987 74.8621 183.883 76.2196 183.631L170.934 166.06C172.291 165.808 173.187 164.503 172.936 163.146L150.396 41.6489C150.144 40.2914 148.839 39.395 147.481 39.6469Z"
        fill="white"
      />
      <path
        d="M128.43 151.235C120.839 154.437 112.486 155.378 104.373 153.945C96.2603 152.512 88.7343 148.766 82.7 143.157C76.6657 137.549 72.38 130.317 70.3583 122.33C68.3366 114.344 68.6649 105.944 71.3039 98.1397C73.9428 90.3355 78.7799 83.4598 85.2336 78.3394C91.6873 73.219 99.4827 70.0719 107.682 69.2766C115.882 68.4812 124.137 70.0714 131.455 73.8561C138.772 77.6407 144.84 83.4585 148.93 90.61"
        stroke="#EA606A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.5 56.73L208.33 70.03C208.743 70.3593 209.009 70.8385 209.07 71.363C209.131 71.8876 208.981 72.4149 208.655 72.83L154.785 141L134.83 125.225L188.7 57.06C189.029 56.6464 189.507 56.3795 190.032 56.3177C190.557 56.2559 191.084 56.4041 191.5 56.73V56.73Z"
        fill="#4155A8"
      />
      <path
        d="M134.83 125.225L133.12 140.585L132.36 147.38C132.329 147.665 132.412 147.951 132.591 148.174C132.77 148.398 133.03 148.542 133.315 148.575C133.484 148.595 133.656 148.575 133.815 148.515L140.25 146.21L154.785 141L134.83 125.225Z"
        fill="#F7DEDA"
      />
      <path
        d="M140.235 146.21L133.12 140.585L132.36 147.38C132.329 147.664 132.412 147.949 132.591 148.173C132.77 148.396 133.031 148.539 133.315 148.57C133.484 148.592 133.655 148.573 133.815 148.515L140.235 146.21Z"
        fill="#EA606A"
      />
      <path
        d="M193.979 53.6026L210.804 66.8997C211.669 67.5829 212.923 67.4359 213.606 66.5715L219.574 59.0201C220.258 58.1557 220.111 56.9011 219.246 56.2179L202.421 42.9209C201.557 42.2377 200.302 42.3846 199.619 43.2491L193.651 50.8004C192.968 51.6649 193.115 52.9195 193.979 53.6026Z"
        fill="#EA606A"
      />
    </g>
    <defs>
      <clipPath id="clip0_374_19">
        <rect width="220" height="220" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IllustrationsIcon;
