export enum WorkCategory {
  Branding = "Branding",
  Illustrations = "Ilustracje",
  Website = "WWW",
  SocialMedia = "Social Media",
  Print = "Druk",
  Animations = "Animacje",
}

interface Work {
  title: string;
  categories: WorkCategory[];
  thumbnailSrc: string;
}

export default Work;
