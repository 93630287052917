import React from "react";
import {
  Box,
  BoxProps,
  Container,
  Grid,
  GridItem,
  VStack,
} from "@chakra-ui/layout";
import Reveal from "../components/Reveal";
import HeroHeading from "../components/HeroHeading";
import SectionHeading from "../components/SectionHeading";
import DashLink from "../components/DashLink";
import useScrollToHandler from "../hooks/useScrollToHandler";
import { menuItems } from "../constants/menuItems";

type QuoteSectionProps = BoxProps;

const QuoteSection: React.FC<QuoteSectionProps> = (props) => {
  const handleScrollTo = useScrollToHandler();

  return (
    <Box bg="primary.500" py="24" {...props}>
      <Container maxW="container.lg">
        <Grid
          gap={10}
          alignItems="center"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(8, 1fr)",
          }}
        >
          <GridItem
            colSpan={6}
            pl={{
              md: 8,
            }}
          >
            <Reveal>
              <HeroHeading color="white" mb="12">
                Inspiracja istnieje, ale musi Cię zastać przy pracy.
              </HeroHeading>

              <SectionHeading>Pablo Picasso</SectionHeading>
            </Reveal>
          </GridItem>

          <GridItem
            pt={{
              base: 8,
              md: 0,
            }}
            colSpan={2}
          >
            <Reveal delay={0.2}>
              <VStack align="start" spacing="4">
                {menuItems.map((item, index) => (
                  <DashLink
                    key={index}
                    href={item.href}
                    onClick={handleScrollTo}
                  >
                    {item.title}
                  </DashLink>
                ))}
              </VStack>
            </Reveal>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default QuoteSection;
