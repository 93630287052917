import React from "react";
import { Heading, HeadingProps, HStack } from "@chakra-ui/layout";
import Dash from "./Dash";

type SectionHeadingProps = HeadingProps;

const SectionHeading: React.FC<SectionHeadingProps> = (props) => (
  <HStack spacing="4">
    <Dash />
    <Heading
      as="h1"
      color="secondary.500"
      fontSize={{
        base: "lg",
        md: "xl",
      }}
      {...props}
    />
  </HStack>
);

export default SectionHeading;
