import Icon from "@chakra-ui/icon";
import { Link, LinkProps, Text } from "@chakra-ui/layout";
import React from "react";
import { IconType } from "react-icons";

type ContactLinkProps = {
  icon: IconType;
  text: string;
} & LinkProps;

const ContactLink: React.FC<ContactLinkProps> = ({ icon, text, ...rest }) => (
  <Link
    display="inline-flex"
    height="12"
    justify="left"
    align="center"
    color="primary.500"
    {...rest}
  >
    <Icon as={icon} w="6" h="6" />
    <Text color="primary.500" ml="6">
      {text}
    </Text>
  </Link>
);

export default ContactLink;
