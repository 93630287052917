import {
  Box,
  BoxProps,
  Center,
  Container,
  Link,
  LinkProps,
  VStack,
} from "@chakra-ui/layout";
import React, { MouseEventHandler, useMemo } from "react";
import SectionHeading from "../components/SectionHeading";
import Parallax from "../components/Parallax";
import Reveal from "../components/Reveal";
import Stain, { StainProps } from "../components/Stain";
import HeroHeading from "../components/HeroHeading";
import Swipe from "../components/Swipe";
import Icon from "@chakra-ui/icon";
import { HiArrowDown } from "react-icons/hi";
import { motion } from "framer-motion";
import StainMask from "../components/StainMask";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { StaticImage } from "gatsby-plugin-image";

const stainProps: StainProps = {
  position: "absolute",
  color: "secondary.500",
  left: "40%",
  top: {
    base: "1rem",
    md: "-2rem",
  },
  w: {
    base: "588px",
    md: "788px",
  },
  h: {
    base: "567px",
    md: "767px",
  },
};

const MotionLink = motion<LinkProps>(Link);

type HeroSectionProps = BoxProps;

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, "base");

  const handleScrollServices: MouseEventHandler = (e) => {
    e.preventDefault();

    const id = e.currentTarget.getAttribute("href").replace(/(.*)#/, "");
    const el = document.getElementById(id);

    const rect = el.getBoundingClientRect();
    window.scrollTo({
      top: rect.top + window.scrollY,
      behavior: "smooth",
    });
  };

  const stain = useMemo(
    () =>
      isDesktop ? (
        <Box {...stainProps}>
          <StaticImage
            src="../images/portrait.jpg"
            style={{
              clipPath: "url(#stain)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Martyna Wątroba"
          />
          <StainMask clipPathId="stain" />
        </Box>
      ) : (
        <Stain {...stainProps} />
      ),
    [isDesktop]
  );

  return (
    <Box {...props}>
      <Parallax offset={160}>
        <Reveal delay={0.3}>{stain}</Reveal>
      </Parallax>

      <Container maxW="container.lg" position="relative">
        <Reveal>
          <VStack align="start" mb="24">
            <SectionHeading>Martyna Wątroba</SectionHeading>
            <HeroHeading>
              Cześć, mam na&nbsp;imię Martyna. Tworzę projekty wizualne.
            </HeroHeading>
          </VStack>
        </Reveal>

        <Reveal delay={0.2}>
          <MotionLink
            href="#usługi"
            display="inline-flex"
            alignItems="center"
            fontWeight="semibold"
            whileHover="hover"
            onClick={handleScrollServices}
          >
            <Center
              w="12"
              h="12"
              border="2px solid"
              borderColor="primary.500"
              borderRadius="full"
              mr="3"
            >
              <Swipe dir="down" offset={12}>
                <Icon as={HiArrowDown} w="5" h="5" />
              </Swipe>
            </Center>
            Więcej poniżej
          </MotionLink>
        </Reveal>
      </Container>
    </Box>
  );
};

export default HeroSection;
