import { MenuItem } from "../types/menuItem";

export const menuItems: MenuItem[] = [
  {
    title: "O mnie",
    href: "/#start",
  },
  {
    title: "Usługi",
    href: "/#usługi",
  },
  {
    title: "Portfolio",
    href: "/#portfolio",
  },
  {
    title: "Kontakt",
    href: "/#kontakt",
  },
];
