import { Heading, HeadingProps } from "@chakra-ui/layout";
import React from "react";

type HeroHeadingProps = HeadingProps;

const HeroHeading: React.FC<HeroHeadingProps> = (props) => (
  <Heading
    as="h2"
    fontSize={{
      base: "4xl",
      sm: "5xl",
      md: "4.25rem",
    }}
    lineHeight="1.2em"
    maxW="40rem"
    {...props}
  />
);

export default HeroHeading;
