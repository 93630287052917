import { Box, Link, LinkProps } from "@chakra-ui/layout";
import { motion, Variants } from "framer-motion";
import React from "react";
import Dash from "./Dash";

const MotionLink = motion<LinkProps>(Link);

const variants: Variants = {
  over: {
    width: "1rem",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
      mass: 1,
    },
  },
  out: {
    width: "0rem",
    transition: {
      ease: "easeOut",
      duration: 0.1,
    },
  },
};

const DashLink = ({ children, ...rest }) => (
  <MotionLink
    display="inline-flex"
    alignItems="center"
    color="secondary.500"
    fontSize="lg"
    fontWeight="bold"
    minW="0"
    height="12"
    px="4"
    initial="out"
    whileHover="over"
    {...rest}
  >
    <motion.div variants={variants}>
      <Dash w="full" />
    </motion.div>

    <Box px="4">{children}</Box>
  </MotionLink>
);

export default DashLink;
