import React, { MouseEventHandler } from "react";
import { motion } from "framer-motion";
import { IconButton } from "@chakra-ui/button";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="square"
    stroke="currentColor"
    {...props}
  />
);

type MenuToggleProps = {
  onToggle: MouseEventHandler;
};

const MenuToggle: React.FC<MenuToggleProps> = ({ onToggle }) => (
  <IconButton
    variant="unstyled"
    aria-label="Otwórz menu"
    minW="8"
    h="8"
    display="inline-flex"
    alignItems="center"
    onClick={onToggle}
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </IconButton>
);

export default MenuToggle;
