import React from "react";
import { Box, BoxProps, Container, Heading } from "@chakra-ui/layout";
import Reveal from "../components/Reveal";
import SectionHeading from "../components/SectionHeading";
import TestimonialCard from "../components/TestimonialCard";
import Carousel from "../components/Carousel";
import Stain from "../components/Stain";
import Parallax from "../components/Parallax";
import Testimonial from "../types/testimonial";

const testimonials: Testimonial[] = [
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    authorName: "Jay Z",
    authorAvatarSrc: "https://via.placeholder.com/128.png/e7e7e7/fff",
  },
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    authorName: "Jay Z",
    authorAvatarSrc: "https://via.placeholder.com/128.png/e7e7e7/fff",
  },
  {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    authorName: "Jay Z",
    authorAvatarSrc: "https://via.placeholder.com/128.png/e7e7e7/fff",
  },
];

type TestimonialsSectionProps = BoxProps;

const TestimonialsSection: React.FC<TestimonialsSectionProps> = (props) => (
  <Box {...props}>
    <Container maxW="container.lg" position="relative">
      <Parallax offset={80}>
        <Stain
          position="absolute"
          color="secondary.500"
          left={{
            base: "-200px",
            lg: "-240px",
          }}
          top="-80px"
          w="488px"
          h="467px"
        />
      </Parallax>

      <Reveal>
        <SectionHeading>Referencje</SectionHeading>
        <Heading as="h2" maxW="lg" mt="3">
          Opinie zadowolonych klientów.
        </Heading>
      </Reveal>
    </Container>

    <Reveal delay={0.2}>
      <Carousel mt="12">
        {testimonials.map((testimonial, index) => () => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      </Carousel>
    </Reveal>
  </Box>
);

export default TestimonialsSection;
