import React from "react";
import { Box, BoxProps, Container, Link, Spacer } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import TextLoop from "../components/TextLoop";
import Reveal from "../components/Reveal";
import HeroHeading from "../components/HeroHeading";
import useScrollToHandler from "../hooks/useScrollToHandler";
import { useBreakpointValue } from "@chakra-ui/media-query";

type CtaSectionProps = BoxProps;

const CtaSection: React.FC<CtaSectionProps> = (props) => {
  const handleScrollTo = useScrollToHandler();
  const buttonSize = useBreakpointValue(
    {
      base: "md",
      md: "lg",
    },
    "base"
  );

  return (
    <Box
      bg="primary.500"
      py={{
        base: 20,
        md: 32,
      }}
      {...props}
    >
      <Container maxW="container.lg">
        <Reveal>
          <HeroHeading color="white">
            <TextLoop slides={["Zaprojektuję", "Zilustruję", "Zaanimuję"]} />
            <br />
            Ci coś ładnego
          </HeroHeading>
        </Reveal>

        <Spacer
          height={{
            base: 8,
            md: 16,
          }}
        />

        <Reveal delay={0.2}>
          <Link href="#kontakt" onClick={handleScrollTo}>
            <Button as="span" colorScheme="secondary" size={buttonSize}>
              Napisz do mnie
            </Button>
          </Link>
        </Reveal>
      </Container>
    </Box>
  );
};

export default CtaSection;
