import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/layout";
import Card, { CardProps } from "./Card";
import { Image } from "@chakra-ui/image";
import Work from "../types/work";

type WorkCardProps = {
  work: Work;
} & CardProps;

const WorkCard: React.FC<WorkCardProps> = ({ work, ...rest }) => (
  <Card {...rest}>
    <VStack spacing="0" align="start">
      <Image
        src={work.thumbnailSrc}
        alt={work.title}
        loading="lazy"
        bg="primary.50"
      />

      <Box
        px={{
          base: 6,
          sm: 8,
        }}
        py="10"
        textAlign="left"
      >
        <Heading as="h2" fontSize="1.5rem">
          {work.title}
        </Heading>

        <Text mt="2">{work.categories.join(", ")}</Text>
      </Box>
    </VStack>
  </Card>
);

export default WorkCard;
