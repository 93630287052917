import React from "react";
import {
  Box,
  BoxProps,
  Container,
  Grid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/layout";
import Card from "../components/Card";
import SectionHeading from "../components/SectionHeading";
import Reveal from "../components/Reveal";
import HeroHeading from "../components/HeroHeading";
import { HiMail } from "react-icons/hi";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import ContactLink from "../components/ContactLink";
import ContactForm from "../components/ContactForm";

type ContactSectionProps = BoxProps;

const ContactSection: React.FC<ContactSectionProps> = (props) => (
  <Box
    bg="linear-gradient(0deg, rgba(65,85,168,1) 50%, rgba(0,0,0,0) 50%)"
    {...props}
  >
    <Container maxW="container.lg">
      <Card
        py={{
          base: 10,
          md: 16,
        }}
        px={{
          base: 6,
          sm: 8,
          md: 16,
        }}
        variant="featured"
      >
        <Reveal>
          <VStack align="start">
            <SectionHeading>Kontakt</SectionHeading>
            <HeroHeading>
              Chcę Cię poznać.
              <br />
              Pogadajmy!
            </HeroHeading>
          </VStack>
        </Reveal>

        <Spacer
          height={{
            base: 10,
            md: 12,
          }}
        />

        <Box
          display={{
            base: "none",
            md: "block",
          }}
        >
          <Reveal delay={0.1}>
            <Text maxW="26em">
              Na tym etapie wiesz już o mnie co nie co. Pewnie już masz nawet
              pomysł, w czym mogłabym Ci pomóc, a ja chętnie go poznam! Jeśli
              brak Ci weny, to wiedz, że u mnie Mukle całymi dniami harcują jak
              szalone, z pewnością zaproponuję coś inspirującego i razem
              opracujemy plan działania.
            </Text>
          </Reveal>

          <Spacer height="12" />
        </Box>

        <Reveal delay={0.2}>
          <Grid
            gap={10}
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
            }}
          >
            <VStack align="left" spacing="4">
              <ContactLink icon={HiMail} text="hello@muklastudio.pl" />
              <ContactLink icon={FaFacebook} text="Facebook" />
              <ContactLink icon={FaLinkedin} text="LinkedIn" />
              <ContactLink icon={FaInstagram} text="Instagram" />
            </VStack>

            <ContactForm />
          </Grid>
        </Reveal>
      </Card>
    </Container>
  </Box>
);

export default ContactSection;
