import Service from "../types/service";

export const services: Service[] = [
  {
    title: "Branding",
    description:
      "Pomysł i konsekwencja to przepis na skuteczną identyfikację wizualną marki. Zaprojektuję to, co jest Twojej firmie najpotrzebniejsze.",
    icon: "branding",
  },
  {
    title: "Ilustracje",
    description:
      "Ilustracja to dla mnie zawsze jest jak rzucona rękawica, wyzwanie, którego nie mogę odpuścić. A im więcej inspiracji i podszeptów Mukli potrzebuję, tym potem jestem bardziej zadowolona z efektu końcowego.",
    icon: "illustrations",
  },
  {
    title: "Strony www",
    description:
      "Witryna internetowa to miejsce w sieci, które jest tylko Twoje! Możesz tutaj urządzić, jak Ci się żywnie spodoba, a ja Ci w tym chętnie pomogę.",
    icon: "websites",
  },
];
