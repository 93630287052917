import React from "react";
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";

type LayoutProps = {
  metaTitle: string;
  metaDescription: string;
};

const Layout: React.FC<LayoutProps> = ({
  metaTitle,
  metaDescription,
  children,
}) => (
  <>
    <Helmet>
      <title>{metaTitle} | Martyna Wątroba</title>
      <meta name="description" content={metaDescription} />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
    </Helmet>

    <Global
      styles={css`
        body {
          overflow-x: hidden;
        }
        * {
          -webkit-tap-highlight-color: transparent;
        }
        *::selection {
          background: var(--chakra-colors-secondary-500);
          color: white;
        }
      `}
    />

    <Header />

    <main>{children}</main>

    <Footer />
  </>
);

export default Layout;
