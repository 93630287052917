import { useState, useRef, useLayoutEffect } from "react";
import {
  useViewportScroll,
  useTransform,
  useSpring,
  motion,
  MotionProps,
} from "framer-motion";
import { TransitionDefinition } from "framer-motion/types/types";

const spring: TransitionDefinition = { stiffness: 300, damping: 90 };

type ParallaxProps = {
  offset?: number;
} & MotionProps;

const Parallax: React.FC<ParallaxProps> = ({ offset = 40, ...rest }) => {
  const elementRef = useRef(null);
  const [elementTop, setElementTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const { scrollY } = useViewportScroll();

  const initialY = elementTop - clientHeight;
  const finalY = elementTop + offset;

  const yRange = useTransform(scrollY, [initialY, finalY], [0, -offset]);
  const y = useSpring(yRange, spring);

  useLayoutEffect(() => {
    const element = elementRef.current;

    const handleResize = () => {
      setElementTop(
        element.getBoundingClientRect().top + window.scrollY ||
          window.pageYOffset
      );
      setClientHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elementRef]);

  return <motion.div ref={elementRef} style={{ y }} {...rest} />;
};

export default Parallax;
