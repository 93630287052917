import { Box, BoxProps, HStack } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/media-query";
import React, { ReactNode, useState } from "react";
import { useSwipeable } from "react-swipeable";
import MotionBox from "./MotionBox";
import { IconButton, IconButtonProps } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import Swipe from "./Swipe";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { Transition } from "framer-motion/types/types";

const MotionIconButton = motion<IconButtonProps>(IconButton);

const spring: Transition = {
  type: "spring",
  stiffness: 100,
  damping: 15,
};

type CarouselProps = {
  children: ((isActive: boolean) => ReactNode)[];
} & BoxProps;

const Carousel: React.FC<CarouselProps> = ({ children, ...rest }) => {
  const [position, setPosition] = useState(1);

  const isFirst = position === 0;
  const isLast = position === children.length - 1;

  const itemWidth = useBreakpointValue(
    {
      base: 100,
      md: 75,
      xl: 40,
    },
    "base"
  );

  const handlers = useSwipeable({
    trackMouse: true,
    onSwiped: (eventData) => {
      if (eventData.dir === "Right") {
        handlePrev();
      }
      if (eventData.dir === "Left") {
        handleNext();
      }
    },
  });

  const handlePrev = () => {
    if (!isFirst) {
      setPosition(position - 1);
    }
  };

  const handleNext = () => {
    if (!isLast) {
      setPosition(position + 1);
    }
  };

  return (
    <Box {...handlers} {...rest}>
      <Box userSelect="none">
        <MotionBox
          display="flex"
          w={`${children.length * itemWidth}vw`}
          animate={{
            x: `${-(position * itemWidth) + 50 - itemWidth / 2}vw`,
          }}
          transition={{
            x: spring,
          }}
        >
          {children.map((child, index) => (
            <MotionBox
              key={index}
              w={`${itemWidth}vw`}
              animate={{
                opacity: index === position ? 1 : 0.5,
              }}
              transition={{
                opacity: spring,
              }}
            >
              <Box px={{ base: 4, md: 6 }}>{child(index === position)}</Box>
            </MotionBox>
          ))}
        </MotionBox>
      </Box>

      <HStack justify="center" spacing="8" mt="8">
        <MotionIconButton
          variant="outline"
          size="sm"
          colorScheme="primary"
          aria-label="Poprzednie"
          onClick={handlePrev}
          whileHover="hover"
        >
          <Swipe dir="left">
            <Icon as={HiArrowLeft} w="4" h="4" />
          </Swipe>
        </MotionIconButton>

        <MotionIconButton
          variant="outline"
          size="sm"
          colorScheme="primary"
          aria-label="Następne"
          onClick={handleNext}
          whileHover="hover"
        >
          <Swipe dir="right">
            <Icon as={HiArrowRight} w="4" h="4" />
          </Swipe>
        </MotionIconButton>
      </HStack>
    </Box>
  );
};

export default Carousel;
