import React from "react";
import { StainPath } from "./Stain";

type StainMaskProps = {
  clipPathId?: string;
};

const StainMask: React.FC<StainMaskProps> = ({ clipPathId }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 788 767"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath
        id={clipPathId}
        clipPathUnits="objectBoundingBox"
        transform="scale(0.00126903553, 0.00130378096)"
      >
        <StainPath />
      </clipPath>
    </defs>
  </svg>
);

export default StainMask;
