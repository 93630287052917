import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/layout";
import Card, { CardProps } from "./Card";
import Service from "../types/service";
import { Image } from "@chakra-ui/image";
import { StaticImage } from "gatsby-plugin-image";
import IllustrationsIcon from "./IllustrationsIcon";
import BrandingIcon from "./BrandingIIcon";
import WebsitesIcon from "./WebsitesIcon";

const servicesIcons: { [key in Service["icon"]]: React.FC } = {
  illustrations: IllustrationsIcon,
  branding: BrandingIcon,
  websites: WebsitesIcon,
};

type ServiceCardProps = {
  service: Service;
} & CardProps;

const ServiceCard: React.FC<ServiceCardProps> = ({ service, ...rest }) => {
  const ServiceIcon = servicesIcons[service.icon];

  return (
    <Card
      px={{
        base: 6,
        sm: 8,
      }}
      py="12"
      {...rest}
    >
      <VStack spacing="4">
        <ServiceIcon />

        <Heading as="h2" fontSize="1.5rem" pt="4">
          {service.title}
        </Heading>

        <Text textAlign="center">{service.description}</Text>
      </VStack>
    </Card>
  );
};

export default ServiceCard;
