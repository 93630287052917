import { Variants } from "framer-motion";
import { Transition } from "framer-motion/types/types";
import React from "react";
import MotionBox, { MotionBoxProps } from "./MotionBox";

const spring: Transition = {
  type: "spring",
  stiffness: 100,
  damping: 20,
};

const variants: Variants = {
  off: { opacity: 0, y: 30 },
  in: { opacity: 1, y: 0 },
};

type RevealProps = {
  delay?: number;
  margin?: string;
  once?: boolean;
} & MotionBoxProps;

const Reveal: React.FC<RevealProps> = ({
  delay = 0,
  once = true,
  margin = "-180px 0px",
  ...rest
}) => (
  <MotionBox
    initial="off"
    whileInView="in"
    viewport={{ margin, once }}
    variants={variants}
    transition={{
      y: spring,
      opacity: spring,
      delay: delay,
    }}
    {...rest}
  />
);

export default Reveal;
