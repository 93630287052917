import React from "react";
import { Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import Card, { CardProps } from "./Card";
import Avatar from "./Avatar";
import Testimonial from "../types/testimonial";

type TestimonialCardProps = {
  testimonial: Testimonial;
} & CardProps;

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  testimonial,
  ...rest
}) => (
  <Card
    py={{
      base: 10,
      sm: 12,
    }}
    px={{
      base: 6,
      sm: 12,
    }}
    {...rest}
  >
    <VStack spacing="8" align="start">
      <Text fontSize="lg">{testimonial.text}</Text>
      <HStack spacing="4">
        <Avatar src={testimonial.authorAvatarSrc} />

        <Heading as="h2" fontSize="1.5rem">
          {testimonial.authorName}
        </Heading>
      </HStack>
    </VStack>
  </Card>
);

export default TestimonialCard;
