import React from "react";

const WebsitesIcon = (props) => (
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_369_33)">
      <path
        d="M110.27 201C160.528 201 201.27 160.258 201.27 110C201.27 59.7421 160.528 19 110.27 19C60.0121 19 19.27 59.7421 19.27 110C19.27 160.258 60.0121 201 110.27 201Z"
        fill="#EA606A"
      />
      <path
        d="M171.64 56.445H49.93C48.5493 56.445 47.43 57.5643 47.43 58.945V153.15C47.43 154.531 48.5493 155.65 49.93 155.65H171.64C173.021 155.65 174.14 154.531 174.14 153.15V58.945C174.14 57.5643 173.021 56.445 171.64 56.445Z"
        fill="#4155A8"
      />
      <path
        d="M47.43 75.64H174.14V153.14C174.14 153.803 173.877 154.439 173.408 154.908C172.939 155.377 172.303 155.64 171.64 155.64H49.93C49.267 155.64 48.6311 155.377 48.1622 154.908C47.6934 154.439 47.43 153.803 47.43 153.14V75.64Z"
        fill="white"
      />
      <path
        d="M58.385 70.035C60.4643 70.035 62.15 68.3494 62.15 66.27C62.15 64.1906 60.4643 62.505 58.385 62.505C56.3056 62.505 54.62 64.1906 54.62 66.27C54.62 68.3494 56.3056 70.035 58.385 70.035Z"
        fill="#EA606A"
      />
      <path
        d="M68.645 70.035C70.7243 70.035 72.41 68.3494 72.41 66.27C72.41 64.1906 70.7243 62.505 68.645 62.505C66.5656 62.505 64.88 64.1906 64.88 66.27C64.88 68.3494 66.5656 70.035 68.645 70.035Z"
        fill="#F5B4AA"
      />
      <path
        d="M78.825 70.035C80.9043 70.035 82.59 68.3494 82.59 66.27C82.59 64.1906 80.9043 62.505 78.825 62.505C76.7456 62.505 75.06 64.1906 75.06 66.27C75.06 68.3494 76.7456 70.035 78.825 70.035Z"
        fill="#F7DEDA"
      />
      <path
        d="M138 116.08L195.335 135.23C195.756 135.37 196.127 135.631 196.403 135.979C196.678 136.328 196.846 136.749 196.885 137.191C196.924 137.634 196.834 138.078 196.624 138.469C196.414 138.861 196.095 139.183 195.705 139.395L178.705 148.65L197.11 166.92C197.323 167.132 197.493 167.384 197.608 167.662C197.724 167.939 197.783 168.237 197.783 168.538C197.783 168.838 197.724 169.136 197.608 169.413C197.493 169.691 197.323 169.943 197.11 170.155L190.5 176.745C190.073 177.17 189.495 177.408 188.893 177.408C188.29 177.408 187.712 177.17 187.285 176.745L168.71 158.28L158.67 175.625C158.451 176.004 158.128 176.311 157.739 176.511C157.35 176.711 156.912 176.795 156.476 176.753C156.041 176.711 155.627 176.545 155.284 176.274C154.94 176.003 154.682 175.639 154.54 175.225L135.135 119C134.994 118.598 134.969 118.165 135.061 117.749C135.154 117.334 135.361 116.953 135.659 116.649C135.957 116.345 136.335 116.13 136.748 116.03C137.162 115.929 137.596 115.947 138 116.08Z"
        fill="#F5B4AA"
      />
      <path
        d="M158.665 116.89C158.74 116.105 158.78 115.31 158.78 114.505C158.78 109.521 157.302 104.649 154.533 100.505C151.764 96.3605 147.828 93.1306 143.224 91.2232C138.619 89.3159 133.552 88.8169 128.664 89.7892C123.775 90.7616 119.285 93.1616 115.761 96.6859C112.237 100.21 109.837 104.7 108.864 109.589C107.892 114.477 108.391 119.544 110.298 124.149C112.206 128.753 115.436 132.689 119.58 135.458C123.724 138.227 128.596 139.705 133.58 139.705C133.915 139.705 134.255 139.705 134.58 139.705"
        stroke="#EA606A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.38 130.635C129.278 130.404 126.308 129.282 123.828 127.404C121.348 125.526 119.464 122.971 118.402 120.047C117.34 117.123 117.145 113.954 117.841 110.922C118.537 107.89 120.094 105.124 122.325 102.956C124.556 100.788 127.366 99.3104 130.417 98.7016C133.468 98.0928 136.63 98.3783 139.522 99.5239C142.414 100.67 144.914 102.626 146.721 105.159C148.527 107.692 149.564 110.692 149.705 113.8"
        stroke="#EA606A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_369_33">
        <rect width="220" height="220" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WebsitesIcon;
