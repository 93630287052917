import { MouseEventHandler, useCallback } from "react";

const useScrollToHandler = (): MouseEventHandler => {
  const handleScrollTo = useCallback((e) => {
    const id = e.currentTarget.getAttribute("href")?.replace(/(.*)#/, "");

    if (id) {
      const el = document.getElementById(id);

      if (el) {
        e.preventDefault();

        const rect = el.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.scrollY,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return handleScrollTo;
};

export default useScrollToHandler;
