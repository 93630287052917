import { FormControl } from "@chakra-ui/form-control";
import Icon from "@chakra-ui/icon";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import React from "react";
import { HiChatAlt, HiMail, HiUser } from "react-icons/hi";

const ContactForm = () => (
  <form>
    <VStack spacing="4" align="start">
      <FormControl id="name">
        <InputGroup>
          <InputRightElement pointerEvents="none">
            <Icon as={HiUser} w="6" h="6" color="primary.200" />
          </InputRightElement>
          <Input type="text" placeholder="Imię" />
        </InputGroup>
      </FormControl>

      <FormControl id="email">
        <InputGroup>
          <InputRightElement>
            <Icon as={HiMail} w="6" h="6" color="primary.200" />
          </InputRightElement>
          <Input type="email" placeholder="E-mail" />
        </InputGroup>
      </FormControl>

      <FormControl id="message">
        <InputGroup>
          <InputRightElement pointerEvents="none">
            <Icon as={HiChatAlt} w="6" h="6" color="primary.200" />
          </InputRightElement>
          <Textarea placeholder="Wiadomość" rows={4} />
        </InputGroup>
      </FormControl>
    </VStack>

    <Button colorScheme="secondary" px="12" mt="8">
      Wyślij
    </Button>
  </form>
);

export default ContactForm;
