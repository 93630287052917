import React from "react";

const BrandingIcon = (props) => (
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M110 201C160.258 201 201 160.258 201 110C201 59.7421 160.258 19 110 19C59.7421 19 19 59.7421 19 110C19 160.258 59.7421 201 110 201Z"
      fill="#4155A8"
    />
    <path
      d="M152 107.644C152.008 113.672 150.696 119.629 148.153 125.103C145.61 130.578 141.897 135.438 137.273 139.348C136.004 140.461 135.003 141.841 134.343 143.388C133.682 144.935 133.378 146.609 133.455 148.287C133.455 148.541 133.455 148.801 133.455 149.055C133.493 149.559 133.525 150.208 133.547 150.836C133.558 151.116 133.512 151.396 133.411 151.658C133.311 151.92 133.158 152.159 132.962 152.361C132.766 152.564 132.531 152.724 132.27 152.834C132.01 152.944 131.73 153 131.447 153H88.5254C88.2424 153 87.9622 152.944 87.7017 152.834C87.4411 152.724 87.2055 152.564 87.009 152.362C86.8125 152.16 86.659 151.92 86.5578 151.658C86.4566 151.396 86.4097 151.116 86.42 150.836C86.42 150.208 86.4691 149.559 86.5073 149.05V149.017C86.5084 147.082 86.1069 145.167 85.3279 143.392C84.5489 141.618 83.4092 140.021 81.98 138.704C76.7628 134.069 72.7969 128.213 70.4431 121.669C68.0893 115.125 67.4224 108.102 68.503 101.237C69.5836 94.3724 72.3774 87.8848 76.6303 82.3646C80.8831 76.8444 86.4599 72.4669 92.8533 69.6304C99.2466 66.794 106.253 65.5885 113.236 66.1239C120.219 66.6592 126.956 68.9183 132.834 72.6956C138.712 76.4729 143.545 81.6485 146.892 87.7514C150.239 93.8542 151.995 100.691 152 107.638V107.644Z"
      fill="#EA606A"
    />
    <path
      d="M130.927 158C131.213 157.999 131.497 158.057 131.76 158.172C132.023 158.286 132.259 158.453 132.454 158.663C132.648 158.873 132.796 159.121 132.889 159.391C132.982 159.661 133.017 159.947 132.992 160.231C132.44 165.917 129.778 171.194 125.528 175.033C121.277 178.873 115.742 181 110 181C104.259 181 98.7233 178.873 94.4727 175.033C90.222 171.194 87.5609 165.917 87.0081 160.231C86.9827 159.947 87.0173 159.66 87.1097 159.39C87.2022 159.119 87.3504 158.871 87.5449 158.661C87.7394 158.451 87.9759 158.284 88.2392 158.17C88.5025 158.056 88.7868 157.998 89.0739 158H130.927Z"
      fill="#F7DEDA"
    />
    <path
      d="M56 155L69 144"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40 95L56 98"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76 45L84 60"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M138 43L131 58"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M179 89L163 94"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M169 149L154 141"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.35 148C113.669 142.17 113.012 136.325 113.242 130.463C113.472 124.602 114.687 118.7 117.56 113.547C118.294 112.246 119.152 110.965 120.4 110.097C121.455 109.372 122.721 108.992 124.014 109.011C125.307 109.031 126.56 109.45 127.591 110.207C128.622 110.965 129.377 112.021 129.746 113.223C130.116 114.424 130.081 115.709 129.646 116.89C128.788 119.198 126.576 120.758 124.274 121.78C119.702 123.83 100.302 123.83 95.7341 121.78C93.4481 120.758 91.2196 119.198 90.3577 116.89C89.9202 115.708 89.8831 114.422 90.2515 113.219C90.62 112.015 91.3749 110.957 92.4065 110.199C93.438 109.44 94.6923 109.02 95.9869 109.001C97.2814 108.981 98.5487 109.362 99.6042 110.089C100.856 110.957 101.714 112.238 102.444 113.539C105.317 118.712 106.548 124.59 106.762 130.455C106.975 136.321 106.351 142.162 105.658 147.992"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default BrandingIcon;
