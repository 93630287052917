import React from "react";
import { Container, HStack } from "@chakra-ui/layout";
import Logo from "./Logo";
import Menu from "./Menu";
import { motion, Transition, useCycle, Variants } from "framer-motion";
import MenuToggle from "./MenuToggle";

const variants: Variants = {
  closed: { color: "#4155a8" },
  open: { color: "#fff" },
};

const transition: Transition = {
  duration: 0.2,
};

const Header = () => {
  const [isMenuOpen, toggleMenuOpen] = useCycle(false, true);

  return (
    <motion.header
      animate={isMenuOpen ? "open" : "closed"}
      initial="closed"
      variants={variants}
      transition={transition}
    >
      <Container maxW="container.xl" py="6" position="relative" zIndex="modal">
        <HStack justify="space-between">
          <Logo />
          <MenuToggle onToggle={() => toggleMenuOpen()} />
        </HStack>
      </Container>

      <Menu isOpen={isMenuOpen} onClose={() => toggleMenuOpen()} />
    </motion.header>
  );
};

export default Header;
