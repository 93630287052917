import React from "react";
import MotionBox, { MotionBoxProps } from "./MotionBox";

export type CardProps = {
  variant?: "base" | "featured";
} & MotionBoxProps;

const Card: React.FC<CardProps> = ({ variant = "base", ...rest }) => {
  let boxShoadow = "0px 4px 45px -12px rgba(65, 85, 168, 0.2)";
  if (variant === "featured") {
    boxShoadow += ", 0px 16px 0px -8px rgba(251, 171, 172, 1)";
  }

  return (
    <MotionBox
      bg="white"
      overflow="hidden"
      borderRadius="3xl"
      boxShadow={boxShoadow}
      {...rest}
    />
  );
};

export default Card;
