import React from "react";
import { Box, Container, HStack, Link, LinkProps } from "@chakra-ui/layout";
import Copyright from "./Copyright";
import Swipe from "./Swipe";
import Icon from "@chakra-ui/icon";
import { HiArrowUp } from "react-icons/hi";
import { motion } from "framer-motion";

const MotionLink = motion<LinkProps>(Link);

const Footer = () => {
  const handleScrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box bg="primary.500" py="12">
      <Container maxW="container.lg">
        <HStack
          justify="space-between"
          py="8"
          borderTop="1px"
          borderTopColor="primary.400"
        >
          <Copyright color="white">Mukla Studio</Copyright>

          <MotionLink
            display="inline-flex"
            alignItems="center"
            color="white"
            fontWeight="normal"
            whileHover="hover"
            onClick={handleScrollTop}
          >
            <Swipe dir="up">
              <Icon as={HiArrowUp} mr="2" />
            </Swipe>
            Do góry
          </MotionLink>
        </HStack>
      </Container>
    </Box>
  );
};

export default Footer;
