import { AnimatePresence, Transition, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";
import MotionBox from "./MotionBox";

const transition: Transition = {
  type: "spring",
  stiffness: 200,
  damping: 20,
  mass: 1.5,
};

const variants: Variants = {
  off: {
    y: 20,
    opacity: 0,
  },
  in: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  out: {
    zIndex: 0,
    y: -10,
    opacity: 0,
  },
};

type TextLoopProps = {
  slides: string[];
  duration?: number;
};

const TextLoop: React.FC<TextLoopProps> = ({ slides, duration = 2 }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let next = index + 1;
      if (next === slides.length) {
        next = 0;
      }
      setIndex(next);
    }, duration * 1000);

    return () => clearTimeout(timeout);
  }, [index, setIndex]);

  return (
    <AnimatePresence>
      <MotionBox
        key={index}
        position="absolute"
        variants={variants}
        initial="off"
        animate="in"
        exit="out"
        transition={transition}
      >
        {slides[index]}
      </MotionBox>
    </AnimatePresence>
  );
};

export default TextLoop;
