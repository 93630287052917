import { BoxProps, Container, Grid, Heading } from "@chakra-ui/layout";
import React from "react";
import Reveal from "../components/Reveal";
import SectionHeading from "../components/SectionHeading";
import WorkCard from "../components/WorkCard";
import { works } from "../constants/works";

type WorksSectionProps = BoxProps;

const WorksSection: React.FC<WorksSectionProps> = (props) => (
  <Container maxW="container.lg" position="relative" {...props}>
    <Reveal>
      <SectionHeading>Portfolio</SectionHeading>
      <Heading as="h2" maxW="lg" mt="3">
        Kilka wybranych prac.
      </Heading>
    </Reveal>

    <Reveal delay={0.2}>
      <Grid
        gap="8"
        mt="12"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
      >
        {works.map((work, index) => (
          <WorkCard key={index} work={work} />
        ))}
      </Grid>
    </Reveal>
  </Container>
);

export default WorksSection;
