import Work, { WorkCategory } from "../types/work";

export const works: Work[] = [
  {
    title: "Aplikacja Eden",
    categories: [
      WorkCategory.Branding,
      WorkCategory.Illustrations,
      WorkCategory.Animations,
      WorkCategory.Website,
      WorkCategory.SocialMedia,
    ],
    thumbnailSrc: "/portfolio/eden-miniatura_860x640.jpg",
  },
  {
    title: "Edyta Mika Fotografia",
    categories: [WorkCategory.Website],
    thumbnailSrc: "/portfolio/edyta-miniatura_860x640.jpg",
  },
  {
    title: "ilustria",
    categories: [
      WorkCategory.Illustrations,
      WorkCategory.Website,
      WorkCategory.SocialMedia,
      WorkCategory.Animations,
    ],
    thumbnailSrc: "/portfolio/ilustria-miniatura_860x640.jpg",
  },
  {
    title: "Nanouk Handwovens",
    categories: [WorkCategory.Branding, WorkCategory.Print],
    thumbnailSrc: "/portfolio/nanouk-miniatura_860x640.jpg",
  },
];
