import { Text, TextProps } from "@chakra-ui/layout";

type CopyrightProps = TextProps;

const Copyright: React.FC<CopyrightProps> = ({ children, ...rest }) => (
  <Text {...rest}>
    &#169; {new Date().getFullYear()} {children}
  </Text>
);

export default Copyright;
