import React from "react";
import { BoxProps, Container, Grid, Heading } from "@chakra-ui/layout";
import SectionHeading from "../components/SectionHeading";
import ServiceCard from "../components/ServiceCard";
import Reveal from "../components/Reveal";
import { services } from "../constants/services";

type ServicesSectionProps = BoxProps;

const ServicesSection: React.FC<ServicesSectionProps> = (props) => (
  <Container maxW="container.lg" {...props}>
    <Reveal>
      <SectionHeading>Co robię?</SectionHeading>
      <Heading as="h2" maxW="18em" mt="3">
        Lubię tworzyć rzeczy proste i&nbsp;miłe&nbsp;dla oczu moich odbiorców.
      </Heading>
    </Reveal>

    <Reveal delay={0.2}>
      <Grid
        gap="8"
        mt="12"
        justifyContent="stretch"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        {services.map((service, index) => (
          <ServiceCard key={index} height="100%" service={service} />
        ))}
      </Grid>
    </Reveal>
  </Container>
);

export default ServicesSection;
