import { Box, Link, LinkProps } from "@chakra-ui/layout";
import { motion, Variants } from "framer-motion";
import React from "react";
import Dash from "./Dash";

const MotionLink = motion<LinkProps>(Link);

const variants: Variants = {
  over: {
    width: "2rem",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
      mass: 1,
    },
  },
  out: {
    width: "0rem",
    transition: {
      ease: "easeOut",
      duration: 0.1,
    },
  },
};

const MenuLink = ({ children, ...rest }) => (
  <MotionLink
    display="inline-flex"
    alignItems="center"
    color="white"
    fontSize={{
      base: "4xl",
      md: "5xl",
    }}
    fontWeight="bold"
    minW="0"
    height={{
      base: "16",
      md: "20",
    }}
    px="4"
    initial="out"
    whileHover="over"
    {...rest}
  >
    <motion.div variants={variants}>
      <Dash w="full" h="3px" />
    </motion.div>

    <Box px="4">{children}</Box>
  </MotionLink>
);

export default MenuLink;
