import React, { useCallback, useEffect, useMemo, useRef } from "react";
import MotionBox from "./MotionBox";
import Position from "../types/position";
import { useEventListener } from "@chakra-ui/hooks";
import { AnimatePresence, motion, Transition, Variants } from "framer-motion";
import { VStack } from "@chakra-ui/layout";
import Stain from "../components/Stain";
import useScrollToHandler from "../hooks/useScrollToHandler";
import { menuItems } from "../constants/menuItems";
import MenuLink from "./MenuLink";

const itemVariants: Variants = {
  closed: {
    x: "-4rem",
    opacity: 0,
  },
  open: {
    x: "0rem",
    opacity: 1,
  },
};

const itemTransition: Transition = {
  type: "spring",
  stiffness: 200,
  damping: 20,
  mass: 1.5,
};

const openTransition: Transition = {
  duration: 0.3,
  ease: "easeInOut",
  delayChildren: 0.3,
  staggerChildren: 0.125,
};

const closeTransition: Transition = { duration: 0.3, ease: "easeOut" };

type MenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  const positionRef = useRef<Position>({ x: 0, y: 0 });

  const variants: Variants = useMemo(
    () => ({
      closed: {
        clipPath: `circle(0% at ${positionRef.current.x}px ${positionRef.current.y}px)`,
        transition: closeTransition,
      },
      open: {
        clipPath: `circle(150% at ${positionRef.current.x}px ${positionRef.current.y}px)`,
        transition: openTransition,
      },
    }),
    [positionRef?.current]
  );

  const handleScrollTo = useScrollToHandler();

  const handleClick = useCallback(
    (e) => {
      handleScrollTo(e);
      onClose();
    },
    [handleScrollTo, onClose]
  );

  useEventListener("mousedown", (e) => {
    positionRef.current = {
      x: e.clientX,
      y: e.clientY,
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          as="nav"
          position="fixed"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          bg="primary.500"
          zIndex="overlay"
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
        >
          <Stain
            color="white"
            opacity="0.3"
            transform="scale(-1, -1) translateY(50%)"
            position="absolute"
            top="50%"
            w="788px"
            h="767px"
            right={{
              base: "30%",
              md: "40%",
            }}
          />

          <VStack
            as="ul"
            listStyleType="none"
            m="0"
            p="0"
            position="absolute"
            w="full"
            align="start"
            top="50%"
            left={{ base: "0", md: "50%" }}
            pl="4"
            transform="translateY(-50%)"
          >
            {menuItems.map((item, index) => (
              <motion.li variants={itemVariants} transition={itemTransition}>
                <MenuLink key={index} href={item.href} onClick={handleClick}>
                  {item.title}
                </MenuLink>
              </motion.li>
            ))}
          </VStack>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default Menu;
