import { Box, BoxProps } from "@chakra-ui/layout";
import React from "react";

export type DashProps = BoxProps;

const Dash: React.FC<DashProps> = (props) => (
  <Box bg="secondary.500" w="2rem" h="2px" {...props} />
);

export default Dash;
