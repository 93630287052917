import React from "react";
import { motion } from "framer-motion";

type SwipeProps = {
  dir: "left" | "right" | "up" | "down";
  offset?: number;
};

const Swipe: React.FC<SwipeProps> = ({
  dir = "right",
  offset = 8,
  ...rest
}) => {
  const move = { x: [], y: [] };

  if (dir === "left" || dir === "right") {
    move.x =
      dir === "right" ? [0, offset, -offset, 0] : [0, -offset, offset, 0];
  } else {
    move.y = dir === "down" ? [0, offset, -offset, 0] : [0, -offset, offset, 0];
  }

  return (
    <motion.span
      variants={{
        hover: {
          ...move,
          opacity: [1, 0, 0, 1],
          transition: {
            ease: "backOut",
            duration: 0.6,
            times: [0, 0.5, 0.5, 1],
          },
        },
      }}
      {...rest}
    />
  );
};

export default Swipe;
