import React from "react";
import { Spacer } from "@chakra-ui/layout";
import CtaSection from "../sections/CtaSection";
import HeroSection from "../sections/HeroSection";
import Layout from "../components/Layout";
import ServicesSection from "../sections/ServicesSection";
import WorksSection from "../sections/WorksSection";
import TestimonialsSection from "../sections/TestimonialsSection";
import ContactSection from "../sections/ContactSection";
import QuoteSection from "../sections/QuoteSection";

const IndexPage = () => {
  return (
    <Layout
      metaTitle="Mukla Studio"
      metaDescription="Mukla Studio to pracownia grafiki cyfrowej, która tworzy branding, strony www, animacje, ilustracje oraz komunikację wizualną w mediach społecznościowych."
    >
      <HeroSection py="32" id="start" />

      <ServicesSection py="12" id="usługi" />

      <CtaSection mb="12" mt="12" />

      <WorksSection py="12" id="portfolio" zIndex="banner" />

      <TestimonialsSection py="12" />

      <ContactSection py="12" id="kontakt" />

      <QuoteSection py="12" />
    </Layout>
  );
};

export default IndexPage;
