import { Image, ImageProps } from "@chakra-ui/image";
import React from "react";

type AvatarProps = ImageProps;

const Avatar: React.FC<AvatarProps> = (props) => (
  <Image
    w="12"
    h="12"
    borderRadius="full"
    bg="gray.100"
    loading="lazy"
    {...props}
  />
);

export default Avatar;
