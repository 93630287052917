import { Box, BoxProps } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import React from "react";

const variants = {
  out: {
    d: "M488.02 753.845C677.445 703.117 824.765 452.211 779.339 259.981C724.833 30.0736 439.349 -59.5024 244.672 42.9147C54.7844 142.904 -101.966 472.912 83.3701 650.715C183.039 746.789 349.428 790.852 488.02 753.845Z",
  },
  in: {
    d: [
      "M488.02 753.845C677.445 703.117 824.765 452.211 779.339 259.981C724.833 30.0736 439.349 -59.5024 244.672 42.9147C54.7844 142.904 -101.966 472.912 83.3701 650.715C183.039 746.789 349.428 790.852 488.02 753.845Z",
      "M508 714C697.425 663.271 779.926 430.73 734.5 238.5C679.994 8.59274 449.177 -53.9169 254.5 48.5002C64.6121 148.489 -104.836 455.697 80.4999 633.5C180.169 729.573 369.408 751.007 508 714Z",
      "M477 699C666.425 648.271 816.426 401.23 771 209C716.494 -20.9073 428.677 -44.4171 234 58C44.1122 157.989 -98.3364 424.697 87 602.5C186.669 698.573 338.408 736.007 477 699Z",
      "M488.02 753.845C677.445 703.117 824.765 452.211 779.339 259.981C724.833 30.0736 439.349 -59.5024 244.672 42.9147C54.7844 142.904 -101.966 472.912 83.3701 650.715C183.039 746.789 349.428 790.852 488.02 753.845Z",
    ],
    transition: {
      duration: 10,
      repeat: Infinity,
      mass: 0.1,
    },
  },
};

export const StainPath = () => (
  <motion.path fill="currentColor" variants={variants} />
);

export type StainProps = BoxProps;

const Stain: React.FC<StainProps> = (props) => (
  <Box transform="transformZ(0px)" {...props}>
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 788 767"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity="0.175"
      initial="out"
      whileInView="in"
      viewport={{ once: false }}
    >
      <StainPath />
    </motion.svg>
  </Box>
);

export default Stain;
